body.admin{
   .report-container{
     clear: both;
     display: block;
     overflow: visible!important;
   }
   .filtered_survey_actions {
     float: none!important;
   }
}
